import React from 'react'
import styles from './index.module.scss'
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import appImg from '../img/app_image.png'



export default class ThirdBlock extends React.Component {
    render() {
        return (
            <div className={styles.main}>
                <h2 className={styles.mainTitle}>Managing Teams Has Never Been Easier</h2>
                <div className={styles.block}>
                    <div className={styles.blockImg}>
                        <img src={appImg} width="auto" height="auto" alt='amazon' />
                    </div>
                    <div className={styles.left}>
                        <Accordion>
                            <AccordionItem expanded={true}>
                                <AccordionItemTitle>
                                    <strong className="acc_header u-position-relative">
                                        Employee Performance Analysis
                                        <div className="accordion__arrow" role="presentation" />
                                    </strong>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    <p>With DueFocus, you can track time and analyze every team's workflow. Our Team Pulse interface allows recording all the activities in real-time mode. Thus, your employees can also access the statistics of their performance, compete with colleagues, plan tasks, and establish communications.</p>
                                </AccordionItemBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemTitle>
                                    <strong className="acc_header u-position-relative u-margin-bottom-s">
                                        Advanced Reporting
                                        <div className="accordion__arrow" role="presentation" />
                                    </strong>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    <p>Simplify the process of creating and analyzing your employee's reports. DueFocus time tracking software provides a clear view of the team's KPI and workflow.</p>
                                </AccordionItemBody>
                            </AccordionItem >
                            <AccordionItem>
                                <AccordionItemTitle>
                                    <strong className="acc_header u-position-relative u-margin-bottom-s">
                                        Invoice Payment Function
                                        <div className="accordion__arrow" role="presentation" />
                                    </strong>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    <p>Directly in the time tracking application, you have the opportunity to expose the invoice to the customer not only based on the data generated by your team but also with manual invoices.</p>
                                </AccordionItemBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemTitle>
                                    <strong className="acc_header u-position-relative u-margin-bottom-s">
                                        Time Management As a Habit
                                        <div className="accordion__arrow" role="presentation" />
                                    </strong>
                                </AccordionItemTitle>
                                <AccordionItemBody>
                                    <p>Using DueFocus is not just about tracking time spent on tasks. It's about developing a habit of time management, thereby increasing efficiency and boosting incomes.</p>
                                </AccordionItemBody>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>
        )
    }
}
