import React from 'react'
import styles from './index.module.scss'
import button from '../buttons.module.scss'

import g_chrome from '../img/google-logo.png'
// import linux from '../img/linux.svg'
// import win from '../img/windows_logo.svg'
// import apple from '../img/apple_logo.svg'

export default class Footer extends React.Component {
  // showModal = () => {
  //   const modal = document.getElementById('modal-sign-up');
  //   if (modal.className === 'show-modal') {
  //     modal.removeAttribute('class');
  //   } else {
  //     modal.className ='show-modal';
  //     document.body.className = 'modal-active';
  //   }
  //     return false;
  // }
  openChromeApp = () => {
    // return window.location.href = '/sign-up/';
    return window.open(
      'https://chrome.google.com/webstore/detail/duefocus-time-tracking-ap/jmjoillacjphggegfecfofhagpcfnhda',
      '_blank'
    );
  }
  render() {
    return (
      <>
        <div className={styles.bottom}>
          <div>
            {/* <span>Available for MacOS, Windows and Linux.</span> */}
            <div className={styles.imgBlock}>
              {/* <img src={win} width="auto" height="auto" alt="windows" />
              <img src={apple} width="auto" height="auto" alt="apple" />
              <img src={linux} width="auto" height="auto" alt="linux" /> */}
              <img src={g_chrome} alt="Chrome Web Store" width={248} height={75} />
            </div>
            <button
              onClick={this.openChromeApp}
              className={button.buttonSignUpBig}
            >
              Get Started
            </button>
          </div>
        </div>
      </>
    )
  }
}
