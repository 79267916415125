import React from 'react'
import styles from './index.module.scss'

import imgBg from "../img/nine_block_parallax/coldstart-02.svg";
// import bg from "../img/nine_block_parallax/background.svg";
import button_img from '../img/nine_block_parallax/button.svg'
// import img1 from "../img/nine_block_parallax/01-source.svg";
// import img2 from '../img/nine_block_parallax/07-source.svg'
// import img3 from '../img/nine_block_parallax/04-source.svg'
// import img4 from '../img/nine_block_parallax/02-source.svg'


export default class NineBlock extends React.Component {
  openCreateAcc = () => {
    return window.location.href = '/sign-up/';
  }
  render() {
    return (
      <>
        {/* <div className={styles.main}>

        <div className={styles.block}>
            <div className={styles.info}>
                <strong className={styles.infoTitle}>Productivity Tracking with a Single Click</strong>
                <p>With DueFocus Cold Start feature begin tracking your projects time automatically or with just one click. What's more, now you can easily analyze and assign tracked time between different tasks particularly.</p>
            </div>
            <div className={styles.parallax}>
                <img width="auto" height="auto" alt="" src={imgBg} />
                <div className={styles.button_img}>
                  <img width="auto" height="auto" alt="" src={button_img} />
                </div>
            </div>
        </div>
     </div> */}
      </>
    )
  }
}
