import * as React from 'react';
import styles from './index.module.scss'
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';


import avatar from '../img/icons/avatar-1-1.png'
import avatar2 from '../img/icons/avatar-2.png'
import avatar4 from '../img/icons/avatar-3-1.png'
import avatar3 from '../img/icons/avatar-4.png'

import star from '../img/icons/star.svg'
// import halfStar from '../img/icons/half-star.svg'
import grayStar from '../img/icons/gray-star.svg'

const name = { name: `Victoria V.`, position: `Lead of Testing Department at <a href="https://testfort.com/" target="_blank">TestFort</a>`, star1: star, star2: grayStar };
const name1 = { name: `Greg P.`, position: `Web Designer`, star1: star, star2: star };
const name2 = { name: `Anthony F.`, position: `Product Owner`, star1: star, star2: star };
const name3 = { name: `Volodymyr K.`, position: `Head of PMO at <a href="https://qarea.com/" target="_blank">QArea</a>`, star1: star, star2: grayStar };

const comment = `"Efficient productivity management in quality assurance enables you to save a ton of money on development. And that’s exactly what we get with DueFocus. This practical tool gives us a clear view of our KPIs and workflows. We get concise performance statistics that enables us to improve our project planning and establish transparent communication with clients."`;
const comment2 = `"Duefocus is a time tracking tool that in my view one of the most helpful for freelancers. With this app I don't have to think about how fairly I will be paid or how to support all of my projects at the same time. Thank you I hope you will keep doing it!"`
const comment3 = `"You guys make some incredibly effective software. Time tracker has been working flawlessly for us. You even cannot imagine how much time your app saved and how productive it was. Your software is something we were looking for a long time" `
const comment4 = `"This automated time tracking solution has been an incredible addition to our time management efforts and employee performance analysis on projects. Its convenient reporting tools provided us with means and data necessary to achieve a significant productivity boost."`

const text = 'Spend more time on writing code and less time reporting. DueFocus time tracking software will let you finish your projects on time without paying attention to the extraneous distractions. Be focused on your agenda rather than allocate your focus.';
const text2 = 'DueFocus time tracking software allows controlling each stage of your accomplishment. Manage all your projects and clients in one place. Facilitate the execution of tasks and increase revenues by creating a single space of communication and work.';
const text3 = 'Time tracking software is a new credibility index. Send time reports to the clients for review, control user timesheets, and estimate issues. Begin to establish a basis of trust between contractors and employers with DueFocus.';
const text4 = 'Be aware of what your remote employees do. Watch how much time and what for your team spend. With DueFocus features, you can vividly measure efficiency and concentration on issues of your team members.';
export default class SecondBlock extends React.Component {

  componentDidMount() {
    document.getElementById('position').innerHTML = name.position;
  }

  visibleText1 = () => {
    document.getElementById('text').innerHTML = comment;
    document.getElementById('avatar').src = avatar;
    document.getElementById('name').innerHTML = name.name;
    document.getElementById('position').innerHTML = name.position;
    document.getElementById('star-1').src = name.star1;
    document.getElementById('star-2').src = name.star2;
  };
  visibleText2 = () => {
    document.getElementById('text').innerHTML = comment2
    document.getElementById('avatar').src = avatar2
    document.getElementById('name').innerHTML = name1.name
    document.getElementById('position').innerHTML = name1.position
    document.getElementById('star-1').src = name1.star1
    document.getElementById('star-2').src = name1.star2

  }
  visibleText3 = () => {
    document.getElementById('text').innerHTML = comment3
    document.getElementById('avatar').src = avatar3
    document.getElementById('name').innerHTML = name2.name
    document.getElementById('position').innerHTML = name2.position
    document.getElementById('star-1').src = name2.star1
    document.getElementById('star-2').src = name2.star2

  }
  visibleText4 = () => {
    document.getElementById('text').innerHTML = comment4
    document.getElementById('avatar').src = avatar4
    document.getElementById('name').innerHTML = name3.name
    document.getElementById('position').innerHTML = name3.position
    document.getElementById('star-1').src = name3.star1
    document.getElementById('star-2').src = name3.star2

  }

  render() {

    return (
      <div className={styles.main}>
        <h2 className={styles.mainTitle}>Time Tracking Software That Suits Everyone</h2>
        <div className={styles.mainWrap}>
          <div className={styles.left}>
            <div>
              <Accordion>
                <AccordionItem expanded={true} onClick={this.visibleText1} >
                  <AccordionItemTitle>
                    <strong className="acc_header u-position-relative">
                      Developers & QA
                      <div className="accordion__arrow" role="presentation" />
                    </strong>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <p>{text}</p>
                  </AccordionItemBody>
                </AccordionItem>
                <AccordionItem onClick={this.visibleText2}>
                  <AccordionItemTitle>
                    <strong className="acc_header u-position-relative u-margin-bottom-s">
                      Freelancers
                      <div className="accordion__arrow" role="presentation" />
                    </strong>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <p>{text2}</p>
                  </AccordionItemBody>
                </AccordionItem >
                <AccordionItem onClick={this.visibleText3}>
                  <AccordionItemTitle>
                    <strong className="acc_header u-position-relative u-margin-bottom-s">
                      Contractors
                      <div className="accordion__arrow" role="presentation" />
                    </strong>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <p>{text3}</p>
                  </AccordionItemBody>
                </AccordionItem>
                <AccordionItem onClick={this.visibleText4}>
                  <AccordionItemTitle>
                    <strong className="acc_header u-position-relative u-margin-bottom-s">
                      Remote Employees
                      <div className="accordion__arrow" role="presentation" />
                    </strong>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <p>{text4}</p>
                  </AccordionItemBody>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
          <div className={styles.right}>
            <div>
              <p id='text'>{comment}</p>
              <div className={styles.stars}>
                <img src={star} width="auto" height="auto" alt="star" />
                <img src={star} width="auto" height="auto" alt="star" />
                <img src={star} width="auto" height="auto" alt="star" />
                <img id='star-1' src={star} width="auto" height="auto" alt="star" />
                <img id='star-2' src={grayStar} width="auto" height="auto" alt="star" />
              </div>
              <div className={styles.person}>
                <img id='avatar' src={avatar} width="auto" height="auto" alt="lead" />
                <div className={styles.name}>
                  <strong className={styles.nameTitle} id='name'>{name.name}</strong>
                  <span id='position'></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
