import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import Land from "../components/land"
import dueFocusLogoSoc from '../components/img/duefocus-social-logo.jpg'
import { Helmet } from "react-helmet";

// import logo from '../components/img/meta_img.png'
// const title = 'Free Time Tracker for Effective Time Management'
const title = 'Duefocus is a free time tracker for remote teams'
// const content = 'DueFocus is a free time tracking software that enables to track time with timesheets, send advanced reports, use idle and sync with popular project management tools.'
const content = 'Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports'
const keyWords = `free time tracker, productivity tool, productivity, time management, time tracking, track timer, work hours tracker, tracking hours for work, track working hours, track time app, time tracking app, time tracking software, timesheet, daily planner, project management, calendar app`

const IndexPage = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      {/* <meta name="robots" content="index" /> */}
      <meta name="robots" content="index, follow" />
      {/* <title>DueFocus - Free Time Tracker & Productivity Software for Remote Teams</title> */}
      <title>Duefocus is a free time tracker for remote teams</title>
      <link rel="canonical" href="https://www.duefocus.com/" />
      <meta name="keywords" content={keyWords} />
      <meta name="title" content={title} />
      <meta name="description" content={content} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={content} />
      <meta property="og:url" content="https://www.duefocus.com/" />
      {/* <meta property="og:image" content={"http://i.imgur.com/SVkp5mR.png"} /> */}
      <meta property="og:image" content={dueFocusLogoSoc} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="https://duefocus.com/" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={content} />
      {/* <meta name="twitter:image" content={"http://i.imgur.com/SVkp5mR.png"} /> */}
      <meta name="twitter:image" content={dueFocusLogoSoc} />
    </Helmet>
    <Land />
  </>
)

export default IndexPage
