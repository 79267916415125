import React from 'react'
import styles from './index.module.scss'
// import { Parallax } from 'react-scroll-parallax';
import button from '../buttons.module.scss'

// import imgBg from '../img/eight_block_parallax/focus-01.svg'
// import img1 from '../img/eight_block_parallax/01.svg'
// import img2 from '../img/eight_block_parallax/02.svg'
// import img3 from '../img/eight_block_parallax/04.svg'
// import img4 from '../img/eight_block_parallax/06.svg'
// import img5 from '../img/eight_block_parallax/green_line.svg'
// import greenBg from '../img/eight_block_parallax/green.svg'
// import redBg from '../img/eight_block_parallax/pink.svg'
// import focus from '../img/eight_block_parallax/focus.svg'
// import bottom_line from '../img/eight_block_parallax/red_line.svg'
// import day from '../img/eight_block_parallax/day.svg'
// import logo from '../img/Logo_icon-new.svg';
import logo1 from '../img/Logo_icon-new-1.svg'

export default class EighthBlock extends React.Component {
    openCreateAcc = () => {
        // return window.location.href = '/sign-up/';
        // return window.location.href = 'https://chrome.google.com/webstore/detail/duefocus-time-tracking-ap/jmjoillacjphggegfecfofhagpcfnhda';
        return window.open(
            'https://chrome.google.com/webstore/detail/duefocus-time-tracking-ap/jmjoillacjphggegfecfofhagpcfnhda',
            '_blank'
          );


    }
    render() {
        return (
            <>
                <div className={styles.main}>

                    {/* <div className={styles.block}>
          <div className={styles.parallax}>
              <img alt="" src={imgBg} /> */}
                    {/*<Parallax className={styles.top_img2} y={[0, 0]} tagOuter="figure">
                  <img src={img1} />
              </Parallax>
              <Parallax className={styles.top_img3} y={[0, 0]} tagOuter="figure">
                  <img src={img3} />
              </Parallax>
              <Parallax className={styles.top_img5} y={[0, 0]} tagOuter="figure">
                  <img src={img5} />
              </Parallax>
              <Parallax className={styles.greenBg} y={[0, 0]} tagOuter="figure">
                  <img src={greenBg} />
              </Parallax>
              <Parallax className={styles.redBg} y={[0, 0]} tagOuter="figure">
                  <img src={redBg} />
              </Parallax>
              <Parallax className={styles.focus} y={[0, 0]} tagOuter="figure">
                  <img src={focus} />
              </Parallax>
              <Parallax className={styles.day} y={[0, 0]} tagOuter="figure">
                  <img src={day} />
              </Parallax>
              <Parallax className={styles.bottom_img1} y={[0, 0]} tagOuter="figure">
                  <img src={img1} />
              </Parallax>
              <Parallax className={styles.bottom_img4} y={[0, 0]} tagOuter="figure">
                  <img src={img4} />
              </Parallax>
              <Parallax className={styles.bottom_line} y={[0, 0]} tagOuter="figure">
                  <img src={bottom_line} />
              </Parallax>*/}
                    {/* </div>
          <div className={styles.info}>
              <strong className={styles.blockTitle}>Discover What Influences Your Efficiency</strong>
              <p>Analyze activities using personal engagement metrics. Our Focus feature helps to control distractions and delve into deep work. Make data-driven decisions for everyday efficiency growth.</p>
          </div>
        </div> */}
                    <div className={styles.getStarted}>
                        <div className={styles.getStartedContainer}>
                            <div>
                                <img width="auto" height="auto" alt="logo" src={logo1} />
                                <div className={styles.getStartedInfo}>
                                    <strong className={styles.getStartedInfoTitle}>Get started with DueFocus</strong>
                                    {/* <p>Free Trial for 60 days</p> */}
                                    <p>Download for Free on Google Web Store</p>
                                </div>
                            </div>
                            <button onClick={this.openCreateAcc} className={button.buttonSignUp}>Start Now</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
