import React from 'react'
// import styles from './index.module.scss'
// import button from '../buttons.module.scss'
import Footer from './Footer'
import FifthBlock from '../PersonalIntegrations/FifthBlock/index'
// import { Link } from 'gatsby';



import "react-responsive-carousel/lib/styles/carousel.min.css";

export default class SeventhBlock extends React.Component {
  
  render() {
    return (
      <>
     <FifthBlock />
     <Footer />
     </>
    )
  }
}
