import { Link } from "gatsby";
import React from "react";
import styles from "../Header/style.module.scss";
// import DropDown from '../HeaderWhite/Features'
// import Pricing from '../HeaderWhite/Pricing'
import close from '../img/icons/close.svg';
// import PDF_Overview from '../../data/DueFocus_Product_Overview.pdf';
// import PDF_PitchDeck from '../../data/DueFocus_PitchDeck.pdf';



import logo from "../img/duefocusLogo .svg";
import logo1 from "../img/duefocusLogo.png";
import button from '../buttons.module.scss'


export default function Header() {
  const [isReferenceBlockVisible, setReferenceBlockVisible] = React.useState(true);

  const handleCloseReferenceBlock = () => {
    document.getElementById("empty-block").removeAttribute('class');
    setReferenceBlockVisible(false);
  }

  const menuActive = () => {
    const hamburger = document.getElementById('nav-icon2');
    if (hamburger.className === 'open') {
      hamburger.removeAttribute('class');
    } else {
      hamburger.className = 'open';
    }
    return false;
  }

  const handleCloseMenu = () => {
    document.getElementById('mobile-menu').classList.remove('menu-open');
    document.body.removeAttribute('class');
    document.getElementById('nav-icon2').removeAttribute('class');
    document.getElementById('header-wrap').removeAttribute('class');
  }

  const handleOpenMenu = () => {
    window.scrollTo(0, 0);
    if (document.getElementById('nav-icon2').className === 'open') {
      document.getElementById('mobile-menu').className += ' menu-open';
      document.getElementById('header-wrap').className = 'header-visible';
      document.body.className = 'modal-active';
    } else {
      document.getElementById('mobile-menu').classList.remove('menu-open');
      document.getElementById('header-wrap').removeAttribute('class');
      document.body.removeAttribute('class');
    }
  }

  const isHeaderFixed = () => {
    if (window.pageYOffset > 70) {
      document.getElementById('header-block-blue').className = 'header-fixed';
      document.getElementById('header-blue').className = 'header-fixed-height'
    } else if (window.pageYOffset === 0) {
      document.getElementById('header-block-blue').removeAttribute('class');
      document.getElementById('header-blue').removeAttribute('class');
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", isHeaderFixed);
    return () => {
      window.removeEventListener("scroll", isHeaderFixed);
    };
  }, [isHeaderFixed]);

  const goToDashboard = () => {
    return window.location.href = 'https://web.duefocus.com/login';
  }

  const openSignUp = () => {
    return window.location.href = '/sign-up/';
  }

  return (
    <div id='header-block-blue'>
      {
        // isReferenceBlockVisible &&
        // <div className={styles.covid}>
        //   {/* <p>Stay connected and productive when you’re working remotely with Free 60-days trial of DueFocus. <Link to='/sign-up/'>Сlick here to start</Link> </p> */}
        //   <p>
        //     <span style={{ display: 'block', textAlign: 'center' }}>UNDER CONSTRUCTION</span>
        //     <span style={{ display: 'block', textAlign: 'center' }}>We are working hard to bring you new experience. That's why you may experience difficulties with our tool and some integrations.</span>
        //     <span style={{ display: 'block', textAlign: 'center' }}>Until then, we look forward to seeing you on our <a href="https://diligences.com/" style={{ color: '#ffffff', textDecoration: 'underline' }}>main</a> website.</span>
        //   </p>
        //   <img onClick={handleCloseReferenceBlock} src={close} alt="close" />
        // </div>
      }
      <div id='header-blue'>
        <div className={styles.headerLeft}>
          <Link to='/'><img className={styles.logo} onClick={handleCloseMenu} alt='duefocus' src={logo1} width="175" height="auto" /></Link>
        </div>
        <div className={styles.headerRight}>
          <div onClick={handleOpenMenu} className={styles.mobileMenu}>
            <div onClick={menuActive} id="nav-icon2">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className={styles.navigation}>
            <ul>
              {/* <li>
                <div>
                  <Link id='link_1' to='/integrations/'>Integrations</Link>
                </div>
              </li> */}
              {/* <li>
                <div>
                  <Link id='link_2' to='/faq/'>FAQ</Link>
                </div>
              </li> */}
              <li>
                <div>
                  <Link id='link_3' to='/download/'>Downloads</Link>
                </div>
              </li>
              {/* <li>
                <div>
                  <Link id='link_4' to='/pricing/'>Pricing</Link>
                </div>
              </li> */}
            </ul>
          </div>
          <div className={styles.buttonsHeader}>
            {/* <a href={PDF_Overview} target="_blank"></a>
            <a href={PDF_PitchDeck} target="_blank"></a> */}
            <button onClick={goToDashboard} className={styles.buttonSignIn}>Log In</button>
            <button onClick={openSignUp} className={button.buttonSignUp}>Sign Up</button>
          </div>
        </div>
      </div>
      <div id='header-wrap' className={styles.modalMobile} />
      {/* <DropDown /> */}
      {/* <Pricing /> */}
    </div>
  )
}




