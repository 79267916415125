import React from 'react'
import styles from './index.module.scss'

import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.scss';
import 'slick-carousel/slick/slick.scss';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import star from '../../img/icons/star.svg'
// import halfStar from '../../img/icons/half-star.svg'
import grayStar from '../../img/icons/gray-star.svg'


const text1 = "“I'm really happy with this app because this is something I was looking for at cheap price and fortunately I found it. The plus is I'm getting features like, activities, screenshots and invoices all in one app. That's why I'm planning for going on long term basis and enjoying this time tracking software.“";
const text2 = "“Thanks guys, you are doing a great job. Honestly right now you blow away any of the competitors by having direct integration with 99% of the other toolsets I use. The fact I can hit one button and it blows up my slack/gcaht feeds. I love your time tracking app. Keep it up!“";
const text3 = "“You guys have done amazing work. DueFocus is really just incredible. Keep up the good work!“";
const text4 = "“Your time tracker is incredible. Now I finally found the solution for both employees and clients. Our team is doing things way faster and finally we don't have to worry about our earnings. Thank you.“";
export default class FifthBlock extends React.Component {

  render() {
    const settings = {
      dots: false,
      infinite: true,
      centerMode: true,
      centerPadding: "250px",
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      speed: 8000,
      autoplaySpeed: 1000,
      cssEase: "linear",
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1324,
          settings:
          {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerPadding: "10px",
            centerMode: true,
          }
        },
        {
          breakpoint: 924,
          settings:
          {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "20px",
          }
        },
      ]
    };

    return (
      <>
        <div className={styles.content}>
          <h2 className={styles.contentTitle}>What Professionals Say</h2>
          <a
            href="https://www.producthunt.com/posts/duefocus?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-duefocus"
            target="_blank"
            rel="noopener noreferrer"
            style={{ paddingLeft: '10px', alignSelf: 'center' }}
            className={styles.mobilePHBadge}>
            <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=180882&theme=light&period=daily" alt="DueFocus - Get rid of distractions & focus on tasks that really matter | Product Hunt Embed" style={{ width: '180px', height: '54px' }} width="180px" height="54px" />
          </a>
          <p>Join the company of successful  people</p>
          <div className={styles.desktopContent}>
            <Slider {...settings}>
              <div >
                <div className={styles.cards}>
                  <p>{text1}</p>
                  <div className={styles.block}>
                    <div className={styles.stars}>
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                    </div>
                    <div className={styles.name}>
                      <strong className={styles.nameTitle}>Imran Z.</strong>
                      <span>Senior Developer</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.cards}>
                  <p>{text2}</p>
                  <div className={styles.block}>
                    <div className={styles.stars}>
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={grayStar} width="auto" height="auto" alt="star" />
                    </div>
                    <div className={styles.name}>
                      <strong className={styles.nameTitle}>Noah H.</strong>
                      <span>Founder of Chromakey.io</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.cards}>
                  <p>{text3}</p>
                  <div className={styles.block}>
                    <div className={styles.stars}>
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                    </div>
                    <div className={styles.name}>
                      <strong className={styles.nameTitle}>Daniel R.</strong>
                      <span>TradeThePlan CEO</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.cards}>
                  <p>{text4}</p>
                  <div className={styles.block}>
                    <div className={styles.stars}>
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={star} width="auto" height="auto" alt="star" />
                      <img src={grayStar} width="auto" height="auto" alt="star" />
                    </div>
                    <div className={styles.name}>
                      <strong className={styles.nameTitle}>Catherine S.</strong>
                      <span>Design Team Lead</span>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <div className={styles.mobileContent}>
            <Carousel
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              cancelable={false}
              width='100%'
            >
              <div>
                <div>
                  <div>
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                  </div>
                  {/* <div>
                    <h4>Imran Z.</h4>
                    <span>Senior Developer</span>
                  </div> */}
                  <div className={styles.name}>
                    <strong className={styles.nameTitle}>Imran Z.</strong>
                    <span>Senior Developer</span>
                  </div>
                </div>
                <p>{text1}</p>
              </div>
              <div>
                <div>
                  <div>
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={grayStar} width="auto" height="auto" alt="star" />
                  </div>
                  {/* <div>
                    <h4>Noah H.</h4>
                    <span>Founder of Chromakey.io</span>
                  </div> */}
                  <div className={styles.name}>
                    <strong className={styles.nameTitle}>Noah H.</strong>
                    <span>Founder of Chromakey.io</span>
                  </div>
                </div>
                <p>{text2}</p>
              </div>
              <div>
                <div className={styles.fix}>
                  <div>
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                  </div>
                  {/* <div>
                    <h4>Daniel R.</h4>
                    <span>TradeThePlan CEO</span>
                  </div> */}
                  <div className={styles.name}>
                    <strong className={styles.nameTitle}>Daniel R.</strong>
                    <span>TradeThePlan CEO</span>
                  </div>
                </div>
                <p>{text3}</p>
              </div>
              <div>
                <div>
                  <div>
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={star} width="auto" height="auto" alt="star" />
                    <img src={grayStar} width="auto" height="auto" alt="star" />
                  </div>
                  {/* <div>
                    <h4>Catherine S.</h4>
                    <span>Design Team Lead</span>
                  </div> */}
                  <div className={styles.name}>
                    <strong className={styles.nameTitle}>Catherine S.</strong>
                    <span>Design Team Lead</span>
                  </div>
                </div>
                <p>{text4}</p>
              </div>
            </Carousel>
          </div>
        </div>
      </>
    )
  }
}
