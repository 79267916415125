import React from "react"
import Main from './Main'
import Header from "./Header/header"
import Footer from "./Footer/footer";
import './style.scss'

const Land = () => {
 return (
   <>
   <Header />
    <Main />
   <Footer />
   </> 
 )
}

export default React.memo(Land);
