import React from 'react'
import styles from './index.module.scss'

import privacy from '../img/icons/Privacy.svg'
// import arrow from '../img/icons/Arrow_right.svg'


export default class SixthBlock extends React.Component {
  render() {
    return (
      <>
        <div className={styles.main}>
          <div className={styles.content}>
            <h2 className={styles.contentTitle}>High Privacy and Security</h2>
            <div>
              <img src={privacy} width="auto" height="auto" alt="privacy" />
              <div className={styles.blockContent}>
                <p>Conformity of the company to the requirements of GDPR guarantees the proper level of the services offered to clients.</p>
                <div className={styles.border} />
                <span className={styles.privacy}>DATA PRIVACY WITH DROPBOX AND GOOGLE DRIVE</span>
                <span className={styles.protection}>Your data is always under protection</span>
              </div>
              <div className={styles.arrow}>
                {/* <img src={arrow} width="auto" height="auto" alt="arrow"/> */}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
