import { Link } from '@reach/router';
import React from 'react'
import styles from './index.module.scss'
import button from '../buttons.module.scss'

// import enterprise from '../img/icons/Enterprise.svg'
// import startup from '../img/icons/startup.svg'
// import freelance from '../img/icons/freelance.svg'
import Integration from '../img/Block_integrations.png'
import integrationMobile from '../img/Block_integrations_mobile.png'



export default class ThirdBlock extends React.Component {
  render() {
    return (
      <>
        {/*<div className={styles.main}>
       <div className={styles.contentBlock}>
        <h3>For Small Teams<br/> Control Your Employees and <br/> Check Their Focus</h3>
       </div>
       <div className={styles.contentList}>
         <div className={styles.block}>
           <img src={freelance} width="auto" height="auto" alt='icon'/>
           <h4>Freelance Studio</h4>
           <p>Make clear and comprehensive reports for your clients</p>
         </div>
         <div className={styles.block}>
           <img src={startup} width="auto" height="auto" alt='icon'/>
           <h4>Startups</h4>
           <p>Spend time and money on things that really matter</p>
         </div>
         <div className={styles.block}>
           <img src={enterprise} width="auto" height="auto" alt='icon'/>
           <h4>Enterprise</h4>
           <p>Analyze business processes and increase revenues </p>
         </div>
       </div>
     </div>*/}
        {/* <div className={styles.blockIntegrations}>
     <h3>Hassle-Free <span>Connection</span> With <span>Your Favorite</span> Project Management Tools</h3>
       <img className={styles.integration} src={Integration} width="auto" height="auto" alt="Integration"/>
       <img className={styles.integrationMobile} src={integrationMobile} width="auto" height="auto" alt="integrationMobile"/>
       <Link to='/integrations/'><button className={button.learnMore}>More Integrations</button></Link>
     </div> */}
      </>
    )
  }
}
