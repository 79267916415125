import React from 'react'
import styles from './index.module.scss'

// import coldStart from '../img/Block_Cold_Time.png'
// import coldStartMini from '../img/Block_Cold_Time_mobile.png'


import img1 from '../img/icons/personal_integration/icon_MultivariateTracking.svg'
import img2 from '../img/icons/personal_integration/icon_FocusedActivities.svg'
import img3 from '../img/icons/personal_integration/icon_RealtimeTeamwork.svg'
import img4 from '../img/icons/personal_integration/icon_EasyReporting.svg'
import img5 from '../img/icons/personal_integration/Icon_FullIntegration.svg'
import img6 from '../img/icons/personal_integration/icon_AutoTask.svg'


export default class FifthBlock extends React.Component {

  render() {
    return (
      <>
        <div className={styles.main}>
          <div id='Features' className={styles.mainContent}>
            <h2>What Makes DueFocus an Innovative Time Tracker</h2>
            {/*<h5>Improve Your Productivity with <span>Cold Start</span></h5>
            <p>With DueFocus Cold Start feature begin tracking your projects time automatically or with just one click. What's more, now you can easily analyze and assign tracked time between different tasks particularly.</p>
            <img className={styles.coldStart} src={coldStart} width="auto" height="auto" alt="coldStart"/>
            <img className={styles.coldStartMobile} src={coldStartMini} width="auto" height="auto" alt="coldStartMini"/>*/}
            {/* <button className={button.buttonFeatures}>See All Features</button> */}
          </div>
          <div className={styles.block}>
            <div>
              <div>
                <img src={img1} width="auto" height="auto" alt='icon' />
                <strong className={styles.blockTitle}>Multivariate Tracking</strong>
                <p>Track time with one-click, manually or against tasks </p>
              </div>
              <div>
                <img src={img2} width="auto" height="auto" alt='icon' />
                {/* <strong className={styles.blockTitle}>Focus Analysis</strong>
              <p>Measure productivity KPI's and cut out distractions</p> */}
                <strong className={styles.blockTitle}>Embedded Task Tracker</strong>
                <p>Track work hours to your tasks and projects in one app</p>
              </div>
              <div className={styles.desktopVisible}>
                <img src={img3} width="auto" height="auto" alt='icon' />
                <strong className={styles.blockTitle}>Real-time Monitoring</strong>
                <p>Be aware of how your team members spend working time</p>
              </div>
            </div>
            <div className={styles.mobileContent}>
              <div>
                <img src={img3} width="auto" height="auto" alt='icon' />
                <strong className={styles.blockTitle}>Real-time Monitoring</strong>
                <p>Be aware of how your team members spend  working time</p>
              </div>
              <div>
                <img src={img4} width="auto" height="auto" alt='icon' />
                <strong className={styles.blockTitle}>Easy Reporting</strong>
                <p>Send .xls reports to your task management tool, email, etc.</p>
              </div>
            </div>
            <div>
              <div className={styles.desktopVisible}>
                <img src={img4} width="auto" height="auto" alt='icon' />
                <strong className={styles.blockTitle}>Easy Reporting</strong>
                <p>Send .xls reports to your task management tool, email, etc.</p>
              </div>
              <div>
                <img src={img5} width="auto" height="auto" alt='icon' />
                {/* <strong className={styles.blockTitle}>Interaction with Integration</strong>
                <p>A direct, comprehensive, two-way API connection</p> */}
                <strong className={styles.blockTitle}>Screenshots</strong>
                <p>Monitor performance & employees</p>
              </div>
              <div>
                <img src={img6} width="auto" height="auto" alt='icon' />
                <strong className={styles.blockTitle}>Stage Mapping</strong>
                <p>Change status and priority of tasks during the time tracking</p>
              </div>
            </div>
            {/* <button className={button.buttonFeatures}>See All Features</button> */}
          </div>
        </div>
      </>
    )
  }
}
