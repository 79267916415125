import React from 'react'
import { Link } from "gatsby";
import styles from './index.module.scss'
import button from '../buttons.module.scss'
import EmptyBlock from '../Header/emptyBlock'
// import desktop from '../img/Duefocus_Image_2.svg'
import desktop from '../img/app_screen-2.png'


import tip from '../img/icons/tip.svg'
import arrow from '../img/icons/Icon_indicator.svg'
// import chrome from '../img/icons/chrome.svg'


export default class FirstBlock extends React.Component {
  openSignUp = () => {
    return window.location.href = '/sign-up/';
  }
  componentDidMount() {
    const icons = document.querySelectorAll('.icon-effect');
    setInterval(_ => {
      icons.forEach(function (element, i) {
        setTimeout(function () {
          element.classList.toggle('active-icons');
        }, 240 * ++i)
      });
    }, icons.length * 200 + 300)
  }
  render() {
    return (
      <>
        <EmptyBlock />
        <div className={styles.body}>
          <div className={styles.main}>
            <div className={styles.left}>
              {/* <h1>Time Tracking & Productivity Software You'll Actually Use</h1> */}
              <h1>Time Tracking & Productivity Chrome App You'll Actually Use</h1>
              {/* <p>Available for MacOS, Windows and Linux.</p> */}
              <p>Available on Google Web Store</p>
              <div className={styles.buttons}>
                <button
                  onClick={this.openSignUp}
                  className={`${button.buttonSignUpBig} ${styles.visibleButton}`}
                >
                  Sign Up for Free
                </button>
              </div>
            </div>
            <div className={styles.right}>
              {/* <div className={styles.tip}>
                <div>For Your Desktop</div>
                <img src={tip} width="auto" height="auto" alt='tip' />
              </div> */}
              <img src={desktop} width="auto" height="auto" alt='Duefocus' />
            </div>
          </div>
          <button
            onClick={this.openSignUp}
            className={`${button.buttonSignUpBig} ${styles.invisibleButton}`}
          >
            Get Started
          </button>
          {/* <div className={styles.integration}>
            <div className={styles.integrationImg}>
              {this.props.images.map((image, k) =>
                <img
                  className='icon-effect'
                  key={k}
                  src={image}
                  width="auto" height="auto" alt="integration"
                />)}
            </div>
            <Link to='/integrations/' className={styles.integrationArrow}>
              <span onClick={this.goTop} className={styles.inVisible}>See all Integrations</span>
              <span className={styles.visible}>See all</span>
              <img className={styles.arrow} src={arrow} width="auto" height="auto" alt="arrow" />
            </Link>

          </div> */}
        </div>
      </>
    )
  }
}
