import React from 'react'
import FirstBlock from '../components/FirstBlock/index'
import SecondBlock from '../components/SecondBlock/index'
import ThirdBlock from '../components/ThirdBlock//index'
import FourthBlock from '../components/FourthBlock//index'
import FifthBlock from '../components/FifthBlock/index'
// import Desktop from '../components/FifthBlock/desktopBlock'
import SixthBlock from '../components/SixthBlock/index'
import EighthBlock from '../components/EighthBlock/index'
import NineBlock from "../components/NineBlock/index";
// import SeventhBlock from '../components/SeventhBlock/index'
import LastBlock from '../components/LastBlock/index'

import img1 from '../components/img/icons/integrations/Logo_tracker_grayscale_trello.svg'
import img2 from '../components/img/icons/integrations/Logo_tracker_grayscale_asana.svg'
import img3 from '../components/img/icons/integrations/Logo_tracker_grayscale_github.svg'
import img4 from '../components/img/icons/integrations/Logo_tracker_grayscale_clickup.svg'
import img5 from '../components/img/icons/integrations/Logo_tracker_grayscale_liquidplanner.svg'
import img6 from '../components/img/icons/integrations/Logo_tracker_grayscale_gitlab.svg'
import img7 from '../components/img/icons/integrations/Logo_tracker_grayscale_jira.svg'
import img8 from '../components/img/icons/integrations/Logo_tracker_grayscale_redmine.svg'
import img9 from '../components/img/icons/integrations/Logo_tracker_grayscale_activecollab.svg'
import img11 from '../components/img/icons/integrations/Logo_tracker_grayscale_teamworks.svg'
import img12 from '../components/img/icons/integrations/Logo_tracker_grayscale_pivotal.svg'
import img13 from '../components/img/icons/integrations/Logo_tracker_grayscale_outlook.svg'
import img14 from '../components/img/icons/integrations/Logo_tracker_grayscale_wunderlis.svg'

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img11,
  img12,
  img13,
  img14
];

const Main = () => {
return (
  <>
    <FirstBlock images={images}/>
    <FifthBlock />
    <FourthBlock />
    <EighthBlock/>
    <NineBlock/>
    <ThirdBlock />
    {/* <Desktop /> */}
    <SecondBlock />
    <SixthBlock />
    {/* <SeventhBlock /> */}
    <LastBlock />
  </>
  )
}

export default React.memo(Main);